import { Injectable } from '@angular/core';
import { LocalStorage } from '../classes/documentstorage';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService implements Storage {
  private storage: Storage;

  constructor(private environmentService: EnvironmentService) {
    this.storage = new LocalStorage();

    if (this.environmentService.isBrowser()) {
      this.storage = localStorage;
    }
  }

  [name: string]: any;

  length: number = 0;

  clear(): void {
    this.storage.clear();
  }

  getItem(key: string): string | null {
    return this.storage.getItem(key);
  }

  key(index: number): string | null {
    return this.storage.key(index);
  }

  removeItem(key: string): void {
    return this.storage.removeItem(key);
  }

  setItem(key: string, value: string): void {
    try {
      return this.storage.setItem(key, value);
    } catch (err) {
      console.log(err);
    }
  }

  getItemJson(key: string): any | null {
    return this.storage.getItem(key)
      ? JSON.parse(this.storage.getItem(key) as string)
      : null;
  }

  setItemJson(key: string, object: any): void {
    try {
      return this.storage.setItem(key, JSON.stringify(object));
    } catch (err) {
      console.log(err);
    }
  }
}
