export class SessionStorage implements Storage {
  [name: string]: any;
  readonly length!: number;
  clear(): void {}
  getItem(key: string): string | null {
    return null;
  }
  key(index: number): string | null {
    return null;
  }
  removeItem(key: string): void {}
  setItem(key: string, value: string): void {}
}

export class LocalStorage implements Storage {
  [name: string]: any;
  readonly length!: number;
  clear(): void {}
  getItem(key: string): string | null {
    return null;
  }
  key(index: number): string | null {
    return null;
  }
  removeItem(key: string): void {}
  setItem(key: string, value: string): void {}
}

export class DocumentStorage {
  constructor(private document: Document | any) {}
  setItem(key: string, value: string) {
    return (this.document['storage'][key] = value);
  }
  getItem(key: string) {
    return this.document['storage'][key];
  }
  clear() {
    delete this.document['storage'];
  }
  removeItem(key: string) {
    delete this.document['storage'][key];
  }
}
